import {createElement, forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {useButton} from '@react-aria/button';
import {useLink} from '@react-aria/link';
import {mergeProps} from '@react-aria/utils';
import {twMerge} from 'tailwind-merge';

import type {AriaButtonProps} from '@react-aria/button';
import type {ComponentPropsWithoutRef, ElementType, MouseEvent} from 'react';
import LoadingSpinner from '../loading-spinner';
import {buttonIconStyles, buttonStyles, unstyledButtonStyles} from './styles';
import type {Icon} from '../../types';
import type {ButtonIconVariantProps, ButtonVariantProps} from './styles';

export type ButtonProps = Omit<AriaButtonProps, 'isDisabled' | 'as' | 'elementType'> &
	ButtonVariantProps &
	ButtonIconVariantProps &
	ComponentPropsWithoutRef<'button'> & {
		target?: string;
		rel?: string;
		iconLeft?: Icon;
		iconRight?: Icon;
		loading?: boolean;
		disabled?: boolean;
		as?: ElementType;
		unstyled?: boolean;
	};

const Button = forwardRef<HTMLElement, ButtonProps>(
	(
		{
			as: Component = 'button',
			loading,
			size,
			color,
			intent,
			pressed,
			iconRight,
			iconLeft,
			href,
			rel,
			target,
			circular,
			disabled,
			type = 'button',
			excludeFromTabOrder,
			onClick,
			onPress,
			onPressUp,
			onPressStart,
			onPressEnd,
			onPressChange,
			onFocusChange,
			className,
			children,
			unstyled,
			...props
		},
		forwardedRef,
	) => {
		const ref = useRef<HTMLElement>(null);

		const clickEventAsPress: NonNullable<typeof onPress> = useCallback(
			e => onClick?.(e as unknown as MouseEvent<HTMLButtonElement>),
			[onClick],
		);

		const {buttonProps, isPressed} = useButton(
			{
				...props,
				onPress: onPress ?? clickEventAsPress,
				onPressUp,
				onPressStart,
				onPressEnd,
				onPressChange,
				onFocusChange,
				excludeFromTabOrder,
				isDisabled: disabled || loading,
				elementType: Component,
				type,
			},
			ref,
		);
		const {linkProps} = useLink({...props, elementType: Component as string, isDisabled: disabled}, ref);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLElement);

		return (
			<Component
				{...mergeProps(props, buttonProps, href ? linkProps : {})}
				className={twMerge(
					unstyledButtonStyles({loading, disabled}),
					!unstyled && buttonStyles({size, color, intent, circular, pressed: isPressed || pressed}),
					className,
				)}
				href={href}
				ref={ref}
				rel={rel}
				target={target}
			>
				{unstyled ? (
					children
				) : (
					<div className="flex items-center justify-center gap-1">
						{iconLeft && createElement(iconLeft, {className: buttonIconStyles({size})})}
						{children}
						{iconRight && createElement(iconRight, {className: buttonIconStyles({size})})}
					</div>
				)}
				{loading && (
					<LoadingSpinner
						className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform opacity-100"
						size={size ?? undefined}
					/>
				)}
			</Component>
		);
	},
);

Button.displayName = 'Button';
export default Button;
export * from './styles';
