'use client';

import LogoDark from '@job-ish/ui/assets/images/job-ish_logo_dark.webp';
import LogoLight from '@job-ish/ui/assets/images/job-ish_logo_light.webp';
import NextImage from 'next/image';

const Logo = () => (
	<>
		<NextImage
			alt="job-ish logo"
			className="hidden dark:block"
			height={75}
			priority
			src={LogoDark}
			width={125}
		/>
		<NextImage
			alt="job-ish logo"
			className="block dark:hidden"
			height={75}
			priority
			src={LogoLight}
			width={125}
		/>
	</>
);

export default Logo;
